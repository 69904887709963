import { CodeInfo } from "../constants/DerivedModels";
import {
  CODE_TYPES,
  Team,
  Org,
  Team__StaffTypes,
  Team__Account,
  Player,
  PrettyPlayer,
  PlayerBundle__AccountType,
  MVPVotingMode,
  JoinTeamErrorCodes
} from "@ollie-sports/models";
import _ from "lodash";
import { fetchPrettyPlayerList } from "../internal-utils/player-utils";
import { BatchTask } from "@ollie-sports/firebase";
import * as express from "express";
import { updatedLinkedTeamsForPlayerBundleId } from "./playerBundle/helpers/updatedLinkedTeamsForPlayerBundleId";
import { getUniversalHelpers } from "../helpers";
import { playerBundle__server__getPlayerBundle } from "./playerBundle/playerBundle__getPlayerBundle";
import { fetchPrettyPlayerBundle } from "../internal-utils/player-bundle-utils";
import { updateDerivedForTeam } from "../internal-utils/team-utils";
import { validateSelfAccountId, validateToken } from "../internal-utils/server-auth";
import { ObjectKeys } from "../utils/object-keys";
import { Team__StaffPresets } from "../constants";
import { DeepPartial } from "../utils/type-helpers";

export async function code__server__fetch(p: { code: string }): Promise<CodeInfo | ""> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const req = await h.Code.query({ where: [{ code: ["==", p.code] }] });

  //Bifrost server doesn't support returning null from an endpoint 🙄
  if (!req.docs.length) {
    return "";
  }

  const code = req.docs[0];

  if (code.codeType === CODE_TYPES.joinOrgAsStaff) {
    const org = await h.Org.getDoc(code.orgId);

    if (!org) {
      return "";
    }

    return {
      ...code,
      org
    };
  } else if (code.codeType === CODE_TYPES.joinTeam) {
    const team = await h.Team.getDoc(code.teamId);

    if (!team) {
      return "";
    }

    return {
      ...code,
      team
    };
  } else if (code.codeType === CODE_TYPES.joinProfileAsAthlete || code.codeType === CODE_TYPES.joinProfileAsGuardian) {
    const playerBundle = await playerBundle__server__getPlayerBundle({ id: code.playerBundleId });

    if (!playerBundle) {
      return "";
    }

    const prettyPlayerBundle = await fetchPrettyPlayerBundle(playerBundle);

    if (!prettyPlayerBundle) {
      return "";
    }

    return {
      ...code,
      prettyPlayerBundle
    };
  } else {
    throw new Error("Unknown code type (server)");
  }
  // SERVER_ONLY_TOGGLE
}

code__server__fetch.auth = () => {};

export async function code__server__getOrgTeams(p: { code: string }): Promise<Team[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const code = await getCode(p.code);

  if (code.codeType !== CODE_TYPES.joinOrgAsStaff) {
    throw new Error("Cannot get org teams for any code other than joinOrgAsStaff");
  }

  const teams = await h.Team.query({ where: [{ deletedAtMS: ["==", 0] }, { orgId: ["==", code.orgId] }] });

  return _.sortBy(teams.docs, "name");
  // SERVER_ONLY_TOGGLE
}

code__server__getOrgTeams.auth = async (req: express.Request) => {};

export async function code__server__getOrg(p: { code: string }): Promise<Org | null> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const code = await getCode(p.code);

  if (code.codeType !== CODE_TYPES.joinOrgAsStaff) {
    throw new Error("Cannot get org for any code other than joinOrgAsStaff");
  }

  return await h.Org.getDoc(code.orgId);
  // SERVER_ONLY_TOGGLE
}

code__server__getOrg.auth = async (req: express.Request) => {};

export async function code__server__getTeamPrettyPlayers(
  p: { codeType: CODE_TYPES.joinTeam; code: string } | { codeType: CODE_TYPES.joinOrgAsStaff; code: string; teamId: string }
): Promise<PrettyPlayer[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const code = await getCode(p.code);

  if (code.codeType !== p.codeType) {
    throw new Error("Passed codeType does not match the fetched codeType for the specified code string ");
  }

  let teamId =
    p.codeType === CODE_TYPES.joinOrgAsStaff ? p.teamId : code.codeType !== CODE_TYPES.joinOrgAsStaff ? code.teamId : "";

  const players = await h.Player.query({ where: [{ teamId: ["==", teamId] }, { deletedAtMS: ["==", 0] }] });

  const vals = await fetchPrettyPlayerList(players.docs);

  return _.sortBy(vals, "accountInfo.firstName");
  // SERVER_ONLY_TOGGLE
}

code__server__getTeamPrettyPlayers.auth = async (req: express.Request) => {
  await validateToken(req);
};

export async function code__server__createTeam(p: { code: string; team: Team }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const code = await getCode(p.code);

  if (code.codeType !== CODE_TYPES.joinOrgAsStaff) {
    throw new Error("Cannot create teams with this code type");
  }

  await Promise.all([
    h.Team.add({ doc: { ...p.team, orgId: code.orgId, disableCovid19Confirm: true, votingMode: MVPVotingMode.standard } }),
    h.TeamSettings.add({ doc: { id: p.team.id } })
  ]);

  return p.team.id;
  // SERVER_ONLY_TOGGLE
}

code__server__createTeam.auth = async (req: express.Request) => {
  await validateToken(req);
};

export async function code__server__createPlayer(p: {
  code: string;
  player: Player;
  autoAddToSquads?: { [key in "a" | "b" | "c"]?: true };
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const code = await getCode(p.code);
  const team = await h.Team.getDoc(p.player.teamId);

  if (!team) {
    throw new Error("No team found for teamId specified on player");
  }

  if (code.codeType === CODE_TYPES.joinOrgAsStaff) {
    if (code.orgId !== team.orgId) {
      throw new Error("Unable to create a player on a team not in the organization affiliated with this code");
    }
  } else if (code.codeType === CODE_TYPES.joinTeam) {
    if (code.teamId !== p.player.teamId) {
      throw new Error("Unable to create player for a different team than the one found on the code");
    }
  } else {
    throw new Error("Unsupported code type for creating a new player");
  }

  await h.Player.add({ doc: p.player });

  const teamUpdate: DeepPartial<Team> = { derived: { activePlayerIds: { [p.player.id]: true } } };

  if (p.autoAddToSquads) {
    ObjectKeys(p.autoAddToSquads).forEach(squad => {
      if (!team?.squads?.[squad]) {
        throw new Error("Invalid auto add to squad!");
      }

      const nonTypeSafePathSoItMatch = ["squadsPlayerMapping", p.player.id, squad]; //Note, this path should be idential to the type safe path above...
      _.set(teamUpdate, nonTypeSafePathSoItMatch, true);
    });
  }
  await h.Team.update({ id: p.player.teamId, doc: teamUpdate });

  await updateDerivedForTeam({ teamId: p.player.teamId, executeImmediate: true });

  return p.player.id;
  // SERVER_ONLY_TOGGLE
}

code__server__createPlayer.auth = async (req: express.Request) => {
  await validateToken(req);
};

type baseJoinTeamType = {
  code: string;
  selfAccountId: string;
  claimedPlayerId?: string;
};

export async function code__server__joinTeam(
  p:
    | (baseJoinTeamType & {
        codeType: CODE_TYPES.joinOrgAsStaff;
        teamId: string;
        staffRole?: Team__StaffTypes;
        memberRole?: "guardian" | "athlete" | "fan";
      })
    | (baseJoinTeamType & {
        codeType: CODE_TYPES.joinTeam;
        memberRole?: "guardian" | "athlete" | "fan";
        staffRole?: Team__StaffTypes;
      })
): Promise<{ status: "success" } | { status: "error"; code: JoinTeamErrorCodes }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const code = await getCode(p.code);

  if (code.codeType !== p.codeType) {
    throw new Error("Passed codeType does not match the fetched codeType for the specified code string ");
  }

  if (!(await h.Account.getDoc(p.selfAccountId))) {
    throw new Error("No account found for selfAccountId: " + p.selfAccountId);
  }

  if (!p.staffRole && !p.memberRole) {
    throw new Error("Must provide a staff or member role to join a team");
  }

  const teamId =
    p.codeType === CODE_TYPES.joinOrgAsStaff ? p.teamId : code.codeType !== CODE_TYPES.joinOrgAsStaff ? code.teamId : "";

  if (p.claimedPlayerId) {
    const player = await h.Player.getDoc(p.claimedPlayerId);
    if (!player) {
      throw new Error("Unable to find player with claimedPlayerId");
    }

    // If joining as an athlete, double check there is not already a self athlete
    if (p.memberRole === "athlete" && player.linkedPlayerBundleId) {
      const playerBundle = await h.PlayerBundle.getDoc(player.linkedPlayerBundleId);
      if (
        playerBundle &&
        Object.values(playerBundle.managingAccounts ?? {}).some(a => a?.type === PlayerBundle__AccountType.selfAthlete)
      ) {
        return { status: "error", code: JoinTeamErrorCodes.selfAthleteAlreadyExistsOnPlayerBundle };
      }
    }
    if (player.teamId !== teamId) {
      throw new Error("claimedPlayerId does not belong to the teamId that will be set");
    }
  }

  const roles =
    p.staffRole && p.claimedPlayerId
      ? { staff: true, guardian: true }
      : p.staffRole
      ? { staff: true }
      : p.memberRole === "athlete"
      ? { athlete: true }
      : { guardian: true };

  const teamAccount: Team__Account = {
    exists: true,
    roles,
    additionalPermissions: {}
  };

  if (p.staffRole) {
    const { additionalPermissions } = Team__StaffPresets("en-us")[p.staffRole];
    teamAccount.staffTitle = p.staffRole;
    teamAccount.additionalPermissions = additionalPermissions;
  }

  await h.Team.update({
    id: teamId,
    doc: {
      accounts: {
        [p.selfAccountId]: teamAccount
      }
    }
  });

  if (p.claimedPlayerId) {
    const player = await h.Player.getDoc(p.claimedPlayerId);
    if (!player) {
      throw new Error(`Unable to find player when claiming a player. PlayerId: ${p.claimedPlayerId}`);
    }

    const playerBundleLinkType = roles.athlete
      ? PlayerBundle__AccountType.selfAthlete
      : roles.guardian
      ? PlayerBundle__AccountType.guardian
      : PlayerBundle__AccountType.guardian;

    if (player.linkedPlayerBundleId) {
      // Since they already have a player bundle then we just link to it

      // Fetch it to make sure it exists first
      let playerBundle = await h.PlayerBundle.getDoc(player.linkedPlayerBundleId);
      if (!playerBundle) {
        throw new Error(`Unable to find playerBundle when claiming a player. PlayerId: ${p.claimedPlayerId}`);
      }

      if (
        playerBundleLinkType === PlayerBundle__AccountType.selfAthlete &&
        Object.keys(playerBundle?.managingAccounts ?? {}).find(
          aId => playerBundle?.managingAccounts?.[aId]?.type === PlayerBundle__AccountType.selfAthlete
        )
      ) {
        // Can't have multiple selfAthletes
        throw new Error(
          `An athlete has already claimed this player bundle. Can't have 2 athletes on the same player bundle. PlayerBundleId: ${playerBundle.id}`
        );
      }

      // Eventually we will not allow them to join an existing playerBundle with just a code. But for the moment we allow them to do so hence we need to update any linked teams for that playerBundle
      await h.PlayerBundle.update({
        id: player.linkedPlayerBundleId,
        doc: {
          managingAccounts: {
            [p.selfAccountId]: {
              exists: true,
              type: playerBundleLinkType
            }
          }
        }
      });

      await updatedLinkedTeamsForPlayerBundleId({ playerBundleId: player.linkedPlayerBundleId });
    } else {
      // Since no player bundle we create one and link the player to it
      const team = await h.Team.getDoc(player.teamId);

      if (!team) {
        throw new Error("Unable to find team!");
      }

      const playerBundleId = h.PlayerBundle.generateId();

      await Promise.all([
        h.PlayerBundle.add({
          doc: {
            id: playerBundleId,
            createdAtMS: Date.now(),
            deletedAtMS: 0,
            virtualAthleteAccount: player.virtualAthleteAccount,
            managingAccounts: { [p.selfAccountId]: { exists: true, type: playerBundleLinkType } },
            derived: {
              activeLinkedOrgs: team.orgId ? { [team.orgId]: true } : {},
              linkedPlayers: { [player.id]: { status: "active", teamId: player.teamId } }
            }
          }
        }),
        h.Player.update({ id: player.id, doc: { linkedPlayerBundleId: playerBundleId } })
      ]);

      await updateDerivedForTeam({ teamId: player.teamId, executeImmediate: true });
    }
  }
  return { status: "success" };
  // SERVER_ONLY_TOGGLE
}

code__server__joinTeam.auth = async (req: express.Request) => {
  await validateSelfAccountId(req, req.body.selfAccountId);
};

async function getCode(codeStr: string) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const codeReq = await h.Code.query({ where: [{ code: ["==", codeStr] }] });

  if (!codeReq.docs || !codeReq.docs.length) {
    throw new Error("Unable to find code");
  }

  const code = codeReq.docs[0];

  if (code.expiryDateMS < Date.now()) {
    throw new Error("Code expired");
  }

  return code;
}

// i18n certified - complete
