import express from "express";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import { SimpleQuery } from "@ollie-sports/firebase-lift";
import { Code, CodeId } from "@ollie-sports/models";
import _ from "lodash";
import { olliePipe__server__sendOlliePipeEvent } from "../olliePipe";

export async function code__server__deleteExpiredCodes(p: {}) {
  // SERVER_ONLY_TOGGLE
  const { appOllieFirestoreV2: h } = getServerHelpers();

  let numberDeletedCodes = 0;
  let failedCodeIds: CodeId[] = [];
  const limit = 200;

  try {
    while (true) {
      const expiredCodesData = await h.Code.query({
        where: [
          {
            expiryDateMS: ["<", Date.now()]
          }
        ],
        limit
      });

      await Promise.all(
        expiredCodesData.docs.map(async a => {
          try {
            await h.Code.delete({ id: a.id });
            numberDeletedCodes = numberDeletedCodes + 1;
          } catch (e) {
            failedCodeIds.push(a.id);
          }
        })
      );
      console.log(`--- DELETED ${numberDeletedCodes} CODES ---`);

      if (expiredCodesData.docs.length !== limit) {
        break;
      }
    }
    if (failedCodeIds.length) {
      await olliePipe__server__sendOlliePipeEvent({
        type: "error-failed-deleting-codes",
        payload: {
          failedCodeIds
        }
      });
    }
  } catch (e) {
    await olliePipe__server__sendOlliePipeEvent({
      type: "error-deleting-codes",
      payload: {
        error: e
      }
    });
  }

  // SERVER_ONLY_TOGGLE
}

code__server__deleteExpiredCodes.auth = async (r: express.Request) => {
  validateToken(r);
};

// i18n certified - complete
